.region {
  position: absolute;
  border: 1px solid gray;
  border-radius: 0.25rem;
  border-width: 2px;
  /* padding: 0.25rem; */
  font-size: x-small;
  font-weight: bold;
  overflow: hidden;
  text-wrap:nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.1s ease-in-out;
  /* z-index: 1000000; */
}

.region:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}
